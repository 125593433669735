export const useExperimentStore = defineStore('experiment', () => {
  const variant = ref<string>('');

  function randomizeVariant() {
    variant.value = Math.random() > 0.5 ? 'A' : 'B';
  }

  return {
    variant,
    randomizeVariant,
  };
});
